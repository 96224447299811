import React from 'react'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware,compose} from 'redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Login from './components/login/Login'
import rootReducer from './reducers/rootReducer'
import Dashboard from './layout/Dashboard'
import Notfound from './layout/Notfound'
import {BrowserRouter as Router, Route,Switch} from 'react-router-dom'
import thunk from 'redux-thunk'
import {loadState,saveState} from './auth/localStorage'

function App(){

const initialData = loadState()
console.log(initialData);
const store = createStore(rootReducer,initialData,
  compose(applyMiddleware(thunk)))

  store.subscribe(function(){
    saveState(store.getState())
  })
  console.log(store);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#0152CC',
      }
    }
  });

  return(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}> 
        <Router >
          <Switch>
            <Route exact path='/' component={Login}/>
            <Route exact path='/dashboard' component={Dashboard}/>
            <Route component={Notfound}/>
          </Switch> 
        </Router>
      </MuiThemeProvider>  
    </Provider>

  );
  
}

export default App
