import React from "react";
import {Avatar,Button,CssBaseline,TextField,Box,Typography,Container} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {Redirect} from 'react-router-dom'
import {useFormik} from "formik"
import * as Yup from 'yup'
import LoadingIndicator from '../../utilsconfig/Loader'
import { red } from "@material-ui/core/colors";
import {connect} from 'react-redux'
import {loguear} from '../../actions/authActions'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  googleButton: {
    margin: theme.spacing(1),
  },
  divSpacing: {
    margin: theme.spacing(2),

  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loader: {
    marginTop: theme.spacing(3, 8, 2)
  },
  erroresSPan:{
    color:red[500]
  }
}));

const  Login = (props) =>{
 
const formik = useFormik({
  initialValues:{
    email:"",
    contrasena:""
  },
  validationSchema:Yup.object({
    email: Yup.string().max(40, "Email solo debe tener maximo 40 caracteres")
    .email("no es un email valido").required("Email es requerido"),
    contrasena: Yup.string()
    .max(40, "Contraseña solo debe tener maximo 40 caracteres")
    .required("Contraseña es requerida")
    .matches(/[a-zA-Z 0-9]/,'Contraseña solo puede tener caracteres aceptados.')
    .min(4, "Contraseña debe tener al menos 4 caracteres"),
  }), 
  onSubmit:(formData)=>{
    /* props.auth.authError="" */
    props.loguear(formData)
}

})

    const classes = useStyles();
    const {authError, auth} = props
    
    console.log(authError)

    if(auth) return <Redirect to="/dashboard"></Redirect>
  
    return (
    
    <Container component="main" maxWidth="xs">
      <CssBaseline />
    
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Coranzapp 
        </Typography>
        <div className={classes.divSpacing}></div>
        
        
        <LoadingIndicator zIndex="tooltip" className={classes.loader}></LoadingIndicator>
        <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            type="email"
            autoFocus
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email)}
            helperText={formik.errors.email}
            value={formik.values.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="contrasena"
            label="Contraseña"
            type="password"
            onChange={formik.handleChange}
            error={Boolean(formik.errors.contrasena)}
            helperText={formik.errors.contrasena}
            value={formik.values.contrasena}
          />
          <Box>
            <span className={classes.erroresSPan}>{authError}</span>
          </Box>
          <Button
            type="submit"
            fullWidth
            size="medium"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Ingresar
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link to="#" variant="body2">
                Olvidaste tu contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/registrar" variant="body2">
                {"¿No tienes una cuenta ? ingresa una aqui"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
      </Box>
    </Container>
    
  );
}

const mapToStateProps =(state)=>{
  return {
    ...state,
    authError: state.auth.authError,
    auth: state.auth.user
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    loguear:(formData)=> dispatch(loguear(formData))
  }
}

export default connect(mapToStateProps, mapDispatchToProps)(Login)