import axios from 'axios'
import { trackPromise } from 'react-promise-tracker';

export const loguear= (formData)=>{

    return (dispatch, getState)=>{
        trackPromise( 
        axios.post("https://devline.cl/cobranza_devline/json/session.php",null, {params:formData},{
        }).then(res => { 
            console.log(res)
            if(res.data.success=="true"){
                console.log(formData.email)
                let obj = formData.email
               /*  Object.assign(obj, {token: res.data.token})
                console.log(obj) */
                dispatch({type: 'LOGIN_SUCCESS',datos:obj})
            }
            else{
                switch(res.data.mensaje){
            
                    case("Usuario no existe en la base de datos"): 
                    dispatch({type: 'LOGIN_ERROR',error:res.data.mensaje}) 
                    console.log("Usuario no existe en la plataforma")
                    
                    break
                    case("El Password es Incorrecto"):  
        
                    dispatch({type: 'LOGIN_ERROR',error:res.data.mensaje})
                    console.log("Contraseña erronea")
                    break
        
                    default: return null
                    
                    }
            }
    }
    ).catch(error =>{
        dispatch({type: 'LOGIN_ERROR',error:error}) 
    })
        );
    }
}


export const desloguear=()=>{
  
    return (dispatch,getState)=>{
        console.log("deslogueo")
        sessionStorage.clear()
        dispatch({type:'LOGOUT_SUCCESS'})
        
    }
        
}

export const updateDatos=(formData)=>{
  
 console.log("updatedata")
    return (dispatch,getState)=>{
        /* console.log(getState) */


        
        trackPromise( axios.post("http://react.devline.cl/backend/accion.php?accion=actDatoPersonal", formData)
            .then(res => {
                if(res.data.estado=="ok"){
                    console.log(res.data.datos)
                    dispatch({type: 'UPDATE_SUCCESS',datos:res.data.datos})
                }
                else{
                    switch(res.data.codEr){
                
                        case(1): 
                        dispatch({type: 'UPDATE_ERROR',error:'Usuario no existe en la plataforma'}) 
                        console.log("Usuario no existe en la plataforma")
                        
                        break
                        case(2):  
            
                        dispatch({type: 'UPDATE_ERROR',error:'Contraseña erronea'})
                        console.log("Contraseña erronea")
                        break
            
                        default: return null
                        
                        }
                }
            })
            .catch(error => {
                console.log(error);
            }));
    }

}