export const loadState = ()=>{

    try{
        const estadoParseado = sessionStorage.getItem('user')
        if(estadoParseado === null){
            return undefined
        }
        return JSON.parse(estadoParseado)  
    }
    catch(err){
        console.log(err)
        return undefined

    }
    
}


export const saveState =(state)=>{
    try{
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('user',serializedState) 
    }
    catch(error){
            console.log(error)
    }

}