const initState={
    posts:[
        {/* id: '1', title: 'Squirtle Laid an Egg', body: 'Lorem ipsum, dolor sit' */},]
    }
    
const documentoReducer =(state= initState, action)=>{

    switch(action.type){
        case 'CREATE_DOCUMENT':
        console.log("create prohec", action.document)
    }
    return state
}

export default documentoReducer