import React, { useState,useEffect,useMemo, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Paper,Grid,Container,Fade} from '@material-ui/core/';
import { connect } from 'react-redux'
import NavBar from './NavBar'
import axios from 'axios'
import ReactTable from "react-table";
import "react-table/react-table.css";
import  { Redirect } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    rootContainer: {
    flexGrow: 1,
    margin: theme.spacing(0),
    marginTop: theme.spacing(3)  
    },
    paper: {
    padding: theme.spacing(2,2,3,2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    },
}));

export const Dashboard = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const {authError, auth} = props

    const handleCLick =(value)=>{

    console.log(value.target.id);
    const id_empresa = value.target.id;
    axios.post("https://devline.cl/cobranza_devline/json/json.php?accion=cambiar_vigencia",
    null,{params: {id_empresa:id_empresa}}).then( 
    res=>
    {
        console.log(res);
        axios.get("https://devline.cl/cobranza_devline/json/json.php?accion=lista_empresas", {
        }).then(res => {
            console.log(res.data.data);
            if(res.status==200){
                setData({
                    post: res.data.data,
                    data: res.data.data,
                    pages: res.data.data.length / 5
                })
            }
        })

    }
    ).catch( (e)=>{console.log(e);}); 
            
            /* console.log(original); */
            /* console.log(id); */
    }

    useEffect(()=>{
        axios.get("https://devline.cl/cobranza_devline/json/json.php?accion=lista_empresas", {
        }).then(res => {
            console.log(res.data.data);
            if(res.status==200){
                setData({
                    post: res.data.data,
                    data: res.data.data,
                    pages: res.data.data.length / 5
                })
            }
        })
    
    },[])  

        const columns = useMemo( () => 
        [{
            Header: 'Rut',
            accessor: 'rut_empresa',
        },{
            Header: 'Empresa',
            accessor: 'nombre_empresa',
        },{
            Header: 'Direccion',
            accessor: 'direccion_empresa',
        },{
            Header: 'Ciudad',
            accessor: 'ciudad_empresa',
        },{
            Header: 'Sistema',
            accessor: 'sistema_gestion',
        },{
            Header: 'Fecha ingreso',
            accessor: 'fecha_ingreso',
        },{
            Header: 'Convenio',
            accessor: 'nombre_convenio',
        },{
            Header: 'Fecha Facturacion',
            accessor: 'fecha_proxima_facturacion',
        },{
            Header: "Vigente",
            accessor: "VIGENTE",
                Cell: props => {
                    if(props.value=="SI"){
                       /*  console.log(props.original.id_empresa); */
                        /* console.log(props.original); */
                        return <div>
                            <span className='number'>{props.value}</span> 
                            <input type="checkbox" id={props.original.id_empresa} onClick={handleCLick}></input>
                        </div> 
                        
                    }
                    else{
                        return <div> <span className='number'>{props.value}</span> 
                        <input type="checkbox" id={props.original.id_empresa} onClick={handleCLick}></input></div> 
                    }

                }
            }]
        ); 
        const traducciones ={rowsText:'Filas',pageText:'Pagina',ofText:'De',previousText: 'Anterior', nextText: 'Siguiente',loadingText:'Cargando...'}
        if(!auth.user) 
        return <Redirect to="/"></Redirect>

        return (
                <Fragment>
                    
                    <NavBar/>
                        <Container maxWidth="lg"> 
                        <Fade in={true} timeout={1000}> 
                            <div className={classes.rootContainer}>
                            <Grid container spacing={3}>
                            
                                <Grid item xs={12}>
                                    <Paper elevation={0} className={classes.paper}>
                                        <Typography component="h1" variant="h6">
                                        Clientes
                                        </Typography>
                                        <ReactTable
                                            {...traducciones}
                                            columns={columns}
                                            data={data.data}
                                            /* loading={this.state.loading} */
                                            pages={data.pages}
                                            manual
                                            defaultPageSize={10}
                                            noDataText={"Loading..."}
                                             // informs React Table that you'll be handling sorting and pagination server-side
                                        />
                                    </Paper>
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Paper elevation={0} className={classes.paper}>
                                    </Paper>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Paper elevation={0} className={classes.paper}>
                                    </Paper>
                                </Grid> */}

                            </Grid>
                            
                            </div>
                            </Fade>  
                        </Container>       
                    </Fragment>      
);
}

const mapStateProps = (state)=>{
    return {
        ...state,
        document: state.document.posts   
    }
    }

export default connect(mapStateProps, null)(Dashboard)
