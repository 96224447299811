const initState ={
    authError: null,
    user:null
}

const authReducer = (state= initState, action)=>{

    console.log(action)
    switch(action.type){
        case('LOGIN_SUCCESS'): 
        return {
            ...state,
            authError: null,
            user: action.datos
        }
        case('LOGIN_ERROR'):
        return {
            ...state,
            authError: action.error,
            user:null
        }

        case('LOGOUT_SUCCESS'):
        return {
            ...state,
            authError: null,
            user:null
        }

        default:return state
    }

}

export default authReducer