import {combineReducers} from 'redux'
import authReducer from './authReducer'
import documentoReducer from './documentoReducer'


const rootReducer = combineReducers({
    auth: authReducer,
    document: documentoReducer
})

export default rootReducer