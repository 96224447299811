import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import { useTheme } from '@material-ui/core/styles';

    const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    const  theme  = useTheme();
    return (
            promiseInProgress && 
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        position: "fixed",
                        zIndex: 9999
                    }
                    }
                >
                <Loader style={{      marginLeft: "40%",
                        marginTop:"25%"}} type="ThreeDots" color={theme.palette.primary.main} height="100" width="100" />
            </div>
        
    );  
}

export default LoadingIndicator